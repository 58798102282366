import { useState, Suspense, lazy } from 'react';

import './assets/scss/general.scss';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';

import DialogPopupContainer from './helpers/DialogPopup';
import { AppContextProvider } from './contexts/AppContext';
import { UserContextProvider } from './contexts/UserContext';
import { AlertContextProvider } from './contexts/AlertContext';
import { CartContextProvider } from './contexts/CartContext.js';
import theme from './styles/CustomTheme';
import { LinearProgress } from '@mui/material';
import ProtectedRoute from './helpers/ProtectedRoute';
import AdminOnlyRoute from './helpers/AdminOnlyRoute';

import { ToastContainer } from 'react-toastify';

const Layout = lazy(() => import('./layouts/layout.jsx'));
const HomePage = lazy(() => import('./screens/HomePage'));
const HomePageApp = lazy(() => import('./screens/HomePageApp'));
const PersonalArea = lazy(() => import('./screens/PersonalArea'));
const Yad2Page = lazy(() => import('./screens/Yad2Page'));
const ErrorScreen = lazy(() => import('./screens/ErrorScreen'));
const Onboarding = lazy(() => import('./screens/Onboarding.jsx'));
const Login = lazy(() => import('./screens/Login'));
const CustomPosts = lazy(() => import('./screens/CustomPosts'));
const Post = lazy(() => import('./screens/Post'));
const Registration = lazy(() => import('./screens/Registration'));

const Galleries = lazy(() => import('./screens/Galleries'));
const OrderFood = lazy(() => import('./screens/OrderFood'));
const SurveyPost = lazy(() => import('./screens/SurveyPost'));
const Shop = lazy(() => import('./screens/Shop'));
const EventOrderQuestions = lazy(() => import('./screens/EventOrderQuestions'));
const Cart = lazy(() => import('./screens/Cart'));
const Order = lazy(() => import('./screens/Order'));
const CvForm = lazy(() => import('./screens/CvForm'));
const LoanForm = lazy(() => import('./screens/LoanForm'));

const FoodReport = lazy(() => import('./screens/FoodReport'));
const ShopReport = lazy(() => import('./screens/ShopReport'));
const FirgunimReport = lazy(() => import('./screens/FirgunimReport'));
const ProductSinglePage = lazy(() => import('./screens/ProductSinglePage'));
const AttractionReport = lazy(() => import('./screens/AttractionReport'));
const MyMessages = lazy(() => import('./screens/MyMessages'));
const Officials = lazy(() => import('./screens/Officials'));
const Phonebook = lazy(() => import('./screens/Phonebook.jsx'));
const ContentPost = lazy(() => import('./screens/ContentPost.jsx'));
const Lomda = lazy(() => import('./screens/Lomda.jsx'));
const Yad2SinglePage = lazy(() => import('./screens/Yad2SinglePage.jsx'));
const GreenWay = lazy(() => import('./screens/GreenWay.jsx'));
const CategoryPage = lazy(() => import('./screens/CategoryPage.jsx'));
const PushNotifications = lazy(() => import('./screens/PushNotifications.jsx'));
const SummerCampReport = lazy(() => import('./screens/SummerCampReport.jsx'));
const RequiredSignedReport = lazy(
  () => import('./screens/RequiredSignedReport.jsx'),
);
const ParkingReport = lazy(() => import('./screens/ParkingReport.jsx'));
const SurveysReport = lazy(() => import('./screens/SurveysReport.jsx'));

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Suspense fallback={<LinearProgress />}>
            <Onboarding />
          </Suspense>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/login',
      element: (
        <Suspense fallback={<LinearProgress />}>
          <Login />
        </Suspense>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/home-page-app',
      element: (
        <Suspense fallback={<LinearProgress />}>
          <HomePageApp />
        </Suspense>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/events',
      element: (
        <ProtectedRoute>
          <Layout>
            <CustomPosts postType="event" />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/category/:type/:id',
      element: (
        <ProtectedRoute>
          <Layout>
            <CategoryPage />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/events/:slug/:id',
      element: (
        <ProtectedRoute>
          <Layout>
            <Post />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/post/:id',
      element: (
        <ProtectedRoute>
          <Layout>
            <ContentPost />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/lomda',
      element: (
        <ProtectedRoute>
          <Layout>
            <Lomda />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/green-way',
      element: (
        <ProtectedRoute>
          <Layout>
            <GreenWay />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/galleries',
      element: (
        <ProtectedRoute>
          <Layout>
            <Galleries />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/yad2',
      element: (
        <ProtectedRoute>
          <Layout>
            <Yad2Page />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/yad2/:id',
      element: (
        <ProtectedRoute>
          <Layout>
            <Yad2SinglePage />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/phonebook',
      element: (
        <ProtectedRoute>
          <Layout>
            <Phonebook />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/push-notifications',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <PushNotifications />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/benefits',
      element: (
        <ProtectedRoute>
          <Layout>
            <CustomPosts postType="benefit" />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/benefits/:slug/:id',
      element: (
        <ProtectedRoute>
          <Layout>
            <Post />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/registration/:slug/:id',
      element: (
        <ProtectedRoute>
          <Suspense fallback={<LinearProgress />}>
            <Layout>
              <Registration />
            </Layout>
          </Suspense>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/survey/:slug/:id',
      element: (
        <ProtectedRoute>
          <Suspense fallback={<LinearProgress />}>
            <Layout>
              <SurveyPost />
            </Layout>
          </Suspense>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/food-order',
      element: (
        <ProtectedRoute>
          <Layout>
            <OrderFood />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/shop',
      element: (
        <ProtectedRoute>
          <Layout>
            <Shop />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/shop/:event-details',
      element: (
        <ProtectedRoute>
          <Layout>
            <EventOrderQuestions />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/cart',
      element: (
        <ProtectedRoute>
          <Layout>
            <Cart />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/home-page',
      element: (
        <ProtectedRoute>
          <Layout>
            <HomePage />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/personal-area',
      element: (
        <ProtectedRoute>
          <Layout>
            <PersonalArea />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/my-messages',
      element: (
        <ProtectedRoute>
          <Layout>
            <MyMessages />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/order/:id',
      element: (
        <ProtectedRoute>
          <Layout>
            <Order />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/product/:id',
      element: (
        <ProtectedRoute>
          <Layout>
            <ProductSinglePage />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/officials',
      element: (
        <ProtectedRoute>
          <Layout>
            <Officials />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/cv-form',
      element: (
        <ProtectedRoute>
          <Layout>
            <CvForm />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/loan-form',
      element: (
        <ProtectedRoute>
          <Layout>
            <LoanForm />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/food-report/:type',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <FoodReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/shop-report',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <ShopReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/firgunim-report',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <FirgunimReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/attraction-report',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <AttractionReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/summer-camp-report',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <SummerCampReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/required-signed-report',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <RequiredSignedReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/parking-report',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <ParkingReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
    {
      path: '/surveys-report',
      element: (
        <ProtectedRoute>
          <AdminOnlyRoute>
            <Layout>
              <SurveysReport />
            </Layout>
          </AdminOnlyRoute>
        </ProtectedRoute>
      ),
      errorElement: (
        <Suspense fallback={<LinearProgress />}>
          <ErrorScreen />
        </Suspense>
      ),
    },
  ]);

  return (
    <div className="App relative">
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <UserContextProvider>
            <AppContextProvider>
              <AlertContextProvider>
                <CartContextProvider>
                  <RouterProvider router={router} />

                  <ToastContainer />
                  <DialogPopupContainer />
                </CartContextProvider>
              </AlertContextProvider>
            </AppContextProvider>
          </UserContextProvider>
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default App;
